import type { ContainerViewModel, ContainerLayoutViewModel, WidgetTabViewModel, WorkspaceViewModel } from '../types';
import type { SetMaximizedContainerAction } from './container';

export type OnRemoveWorkspace = { type: 'removeWorkspaceEvent' };

export const removeWorkspace = (): OnRemoveWorkspace => ({
    type: 'removeWorkspaceEvent',
});

export type SaveCurrentWorkspaceAction = {
    type: 'saveWorkspaceEvent';
    saveType: 'save' | 'saveAs';
};

export const saveWorkspace = (saveType: SaveCurrentWorkspaceAction['saveType']): SaveCurrentWorkspaceAction => ({
    type: 'saveWorkspaceEvent',
    saveType,
});

export type SaveWorkspaceAsPrimaryAction = {
    type: 'saveWorkspaceAsPrimary';
    id: string;
};

export const makeWorkspacePrimary = (id: SaveWorkspaceAsPrimaryAction['id']): SaveWorkspaceAsPrimaryAction => ({
    type: 'saveWorkspaceAsPrimary',
    id,
});

export type CreateWorkspaceAction = {
    type: 'createBlankWorkspaceEvent';
};

export const createBlankWorkspace = (): CreateWorkspaceAction => ({
    type: 'createBlankWorkspaceEvent',
});

export type SubmitNameForSavingAction = {
    type: 'submitNameForSaving';
    name: string;
};

export const submitNameForSaving = (name: string): SubmitNameForSavingAction => {
    return {
        type: 'submitNameForSaving',
        name,
    };
};

export type WorkspaceEvent =
    | CreateWorkspaceAction
    | OnRemoveWorkspace
    | SaveCurrentWorkspaceAction
    | SubmitNameForSavingAction;

export type InitializeWorkspaceData = {
    type: 'initializeWorkspace';
    workspace: WorkspaceViewModel;
    containers: ContainerViewModel[];
    layouts: ContainerLayoutViewModel[];
    tabs: WidgetTabViewModel[];
};

export const initializeNormalizedData = (
    workspace: WorkspaceViewModel,
    containers: ContainerViewModel[],
    layouts: ContainerLayoutViewModel[],
    tabs: WidgetTabViewModel[],
): InitializeWorkspaceData => ({
    type: 'initializeWorkspace',
    workspace,
    containers,
    layouts,
    tabs,
});

export type SetSavingTrueAction = { type: 'setSavingTrueAction' };

export const setSavingTrue = (): SetSavingTrueAction => ({
    type: 'setSavingTrueAction',
});

export type CompletedSavingWorkspaceAction = { type: 'completedSavingWorkspace' };

export const savingCompleted = (): CompletedSavingWorkspaceAction => ({
    type: 'completedSavingWorkspace',
});

export type WorkspaceSavingFailedAction = { type: 'workspaceSavingFailed' };

export const workspaceSavingFailed = (): WorkspaceSavingFailedAction => ({
    type: 'workspaceSavingFailed',
});

export type SetResearchingWorkspaceAction = {
    type: 'setResearchingWorkspace';
};

export const setResearchingWorkspace = (): SetResearchingWorkspaceAction => ({
    type: 'setResearchingWorkspace',
});

export type CompleteWorkspaceResearchAction = {
    type: 'completeWorkspaceResearch';
};

export const completeWorkspaceResearch = (): CompleteWorkspaceResearchAction => ({
    type: 'completeWorkspaceResearch',
});

export type UpdateWorkspaceSettings = {
    type: 'updateWorkspaceSettings';
    settings: {
        name: string;
        isPrimaryWorkspace: boolean;
        isLocked: boolean;
        snappingGridEnabled: boolean;
        activeBorderColor: string;
    };
};

export const updateWorkspaceSettings = (settings: UpdateWorkspaceSettings['settings']): UpdateWorkspaceSettings => ({
    type: 'updateWorkspaceSettings',
    settings,
});

export type WorkspaceAction =
    | UpdateWorkspaceSettings
    | CompleteWorkspaceResearchAction
    | CompletedSavingWorkspaceAction
    | InitializeWorkspaceData
    | SetResearchingWorkspaceAction
    | SetSavingTrueAction
    | WorkspaceEvent
    | WorkspaceSavingFailedAction
    | SetMaximizedContainerAction;
