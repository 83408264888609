import type { IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { Strategy } from 'src/contracts/strategy';

export type UserDoubleClickedSymbolFromTableAction = {
    type: 'userDoubleClickedSymbolFromTable';
    symbol: string;
    tabId: string;
};

export const userDoubleClickedSymbolFromTable = (
    tabId: string,
    symbol: string,
): UserDoubleClickedSymbolFromTableAction => ({
    symbol,
    tabId,
    type: 'userDoubleClickedSymbolFromTable',
});

export type SetResultsStrategyAction = {
    type: 'setResultsStrategy';
    tabId: string;
    strategy: Strategy<IndicatorImportViewModel>;
};

export const setResultsStrategy = (
    tabId: string,
    strategy: Strategy<IndicatorImportViewModel>,
): SetResultsStrategyAction => ({
    type: 'setResultsStrategy',
    tabId,
    strategy,
});

export type ResultsWidgetActions = UserDoubleClickedSymbolFromTableAction | SetResultsStrategyAction;
