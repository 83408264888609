import type { ModalInstance } from '../types';

/**
 * @deprecated
 */
export type CloseActiveModalAction<T extends 'cancel' | string> = {
    type: 'close-modal';
    result: T;
};

/**
 * @deprecated
 */
export const closeActiveModal = <T extends string>(result: T): CloseActiveModalAction<T> => ({
    type: 'close-modal',
    result,
});

/**
 * @deprecated
 */
export const cancelVerifyWorkspaceModal = (): CloseActiveModalAction<'cancel'> => ({
    result: 'cancel',
    type: 'close-modal',
});

/**
 * @deprecated
 */
export const workspaceSavingOver = (): CloseActiveModalAction<'saved'> => ({ result: 'saved', type: 'close-modal' });

/**
 * @deprecated
 */
export type OpenModalAction = {
    type: 'open-modal';
    modal: ModalInstance;
};

/**
 * @deprecated
 */
export const openModal = (modal: ModalInstance): OpenModalAction => ({
    type: 'open-modal',
    modal: modal,
});

/**
 * @deprecated
 */
export type CloseWorkspaceSaveModalAction = { type: 'close::workspace-save::modal' };
/**
 * @deprecated
 */
export const closeSaveWorkspaceModal = (): CloseWorkspaceSaveModalAction => ({ type: 'close::workspace-save::modal' });

/**
 * @deprecated
 */
export type AllowTemplateWorkspaceToSaveAction = { type: 'allow-template-workspace-to-save' };
/**
 * @deprecated
 */
export const allowTemplateWorkspaceToSave = (): AllowTemplateWorkspaceToSaveAction => ({
    type: 'allow-template-workspace-to-save',
});

/**
 * @deprecated
 */
export type CancelWorkspaceSaveModalActuon = { type: 'cancel-close::workspace-save::modal' };
/**
 * @deprecated
 */
export const cancelWorkspaceSaveModal = (): CancelWorkspaceSaveModalActuon => ({
    type: 'cancel-close::workspace-save::modal',
});

/**
 * @deprecated
 */
export type AllowTemplateUpdateAction = { type: 'save-template::workspace-save::modal' };
/**
 * @deprecated
 */
export const allowTemplateUpdate = (): AllowTemplateUpdateAction => ({
    type: 'save-template::workspace-save::modal',
});

/**
 * @deprecated
 */
export type OpenNewWorkspaceTemplateLibraryAction = {
    type: 'workspace-template-library::open';
};
/**
 * @deprecated
 */
export const openNewWorkspaceTemplateLibrary = (): OpenNewWorkspaceTemplateLibraryAction => ({
    type: 'workspace-template-library::open',
});

/**
 * @deprecated
 */
export type OverlayAction =
    | OpenModalAction
    | CloseActiveModalAction<'cancel' | string>
    | CloseWorkspaceSaveModalAction
    | AllowTemplateWorkspaceToSaveAction
    | CancelWorkspaceSaveModalActuon
    | AllowTemplateUpdateAction
    | OpenNewWorkspaceTemplateLibraryAction;
