import type { ChartAxis, ChartPane, ChartSeries } from 'src/contracts/workspace';

export const timestampColumnName = 'timestamp';

export const timeSeriesPaneNames = ['Top', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th'];
export const timeSeriesAxisNames = ['Main', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th'];

export const BLANK_SERIES: ChartSeries = {
    id: '',
    symbol: undefined,
    type: 'line',
    dataSource: {},
    hidden: false,
};
export const BLANK_AXIS: ChartAxis = { id: '', series: [] };
export const BLANK_PANE: ChartPane = {
    id: '',
    symbol: null,
    height: null,
    axes: [BLANK_AXIS],
};

/** ID of the main series that drives the symbol used for the widget */
export const PRIMARY_SERIES_ID = 'main';

/** ID of the volume series that uses the primary symbol for the widget */
export const VOLUME_SERIES_ID = 'Volume';
