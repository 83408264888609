export type UpdatePositionsSymbolFilterAction = { type: 'updatePositionsSymbolFilter'; tabId: string; symbol: string };
export const updatePositionsSymbolFilter = (tabId: string, symbol: string): UpdatePositionsSymbolFilterAction => ({
    tabId,
    symbol,
    type: 'updatePositionsSymbolFilter',
});

export type UpdatePositionsAccountAction = { type: 'updatePositionsAccount'; tabId: string; accountId: string };
export const updatePositionsAccount = (tabId: string, accountId: string): UpdatePositionsAccountAction => ({
    type: 'updatePositionsAccount',
    tabId,
    accountId,
});

export type UserDoubleClickedPositionFromTableAction = {
    type: 'userDoubleClickedPositionFromTable';
    symbol?: string;
    accountId?: string;
    quantity?: number;
    tabId: string;
};

export const userDoubleClickedPosition = (
    tabId: string,
    symbol?: string,
    accountId?: string,
    quantity?: number,
): UserDoubleClickedPositionFromTableAction => ({
    symbol,
    accountId,
    quantity,
    tabId,
    type: 'userDoubleClickedPositionFromTable',
});

export type PositionsActions =
    | UpdatePositionsAccountAction
    | UpdatePositionsSymbolFilterAction
    | UserDoubleClickedPositionFromTableAction;
