import type { NativeState } from '../reducers/native';

export const initNativeAction = (payload: Partial<NativeState>) => ({
    type: 'native::init' as const,
    payload,
});
export type InitNativeAction = ReturnType<typeof initNativeAction>;

export const nativeTickersHide = () => ({
    type: 'native::tickers-hide' as const,
});
export type NativeTickersHideAction = ReturnType<typeof nativeTickersHide>;

export const nativeTickersShow = () => ({
    type: 'native::tickers-show' as const,
});
export type NativeTickersShowAction = ReturnType<typeof nativeTickersShow>;

export type NativeAction = InitNativeAction | NativeTickersHideAction | NativeTickersShowAction;
