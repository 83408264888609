import type { RootState } from '../../';
import { appConfig } from 'src/config';
import type { LocatesSocketConnectionSettings } from 'src/store/channels/locates';

export function getLocatesAuthPayloadFromState({ auth }: RootState): string | undefined {
    if (typeof auth.accessToken !== 'object') {
        throw new Error('Access token not available');
    }

    return auth?.masqAccessToken?.token ?? auth?.accessToken?.token;
}

export function getLocatesSocketConnectionSettingsFromState(_: RootState): LocatesSocketConnectionSettings | undefined {
    return appConfig.locatesApi ? { uri: appConfig.locatesApi } : undefined;
}
