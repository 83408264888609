import type { AppAction } from '../actions';
import type { Middleware } from 'redux';
import { LOCATION_CHANGE } from 'redux-first-history';

export const nativeNavigationHelper: Middleware = () => (next) => (action: AppAction) => {
    /**
     * Do not allow redux-first-history to manipulate the navigation state directly from desktop windows.
     *
     * Windows will sometimes call `createReduxHistory` to connect a local memory history to the redux proxy, and
     * we don't want that redux history initialization to trigger a navigation.
     *
     * @@router/CALL_HISTORY_METHOD signals _intent_ by a window to change the navigation state,
     * whereas @@router/LOCATION_CHANGE directly modifies the location.
     */
    if (action.type === LOCATION_CHANGE && action.meta?.webContentsId !== undefined) {
        return;
    }

    next(action);
};
