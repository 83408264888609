export type UpdateMessagesSymbolFilterAction = { type: 'updateMessagesSymbolFilter'; tabId: string; symbol: string };
export const updateMessagesSymbolFilter = (tabId: string, symbol: string): UpdateMessagesSymbolFilterAction => ({
    tabId,
    symbol,
    type: 'updateMessagesSymbolFilter',
});
export type UpdateMessagesAccount = { type: 'updateMessagesAccount'; tabId: string; accountId: string };
export const updateMessagesAccount = (tabId: string, accountId: string): UpdateMessagesAccount => ({
    tabId,
    accountId,
    type: 'updateMessagesAccount',
});
export type MessagesActions = UpdateMessagesSymbolFilterAction | UpdateMessagesAccount;
