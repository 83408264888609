import isNative from 'lodash/isNative';
import { all, put, select, spawn, take } from 'redux-saga/effects';
import { localforage } from 'src/lib/serialization/localForage';
import type { RootState } from 'src/store';
import { initNativeAction } from 'src/store/actions/native';
import { NativeStateSchema } from 'src/store/reducers/native';

const NATIVE_STATE_KEY = 'REDUX_NATIVE_STATE';

function* syncNativeState() {
    try {
        const nativeState = NativeStateSchema.parse((yield localforage.getItem(NATIVE_STATE_KEY)) ?? {});

        if (nativeState) {
            yield put(initNativeAction(nativeState));
        }
    } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error('Failed to load native state from localforage', e);
    }

    let lastState: RootState['native'] = yield select((state: RootState) => state.native);

    while (true) {
        yield take('*');
        const nextState: RootState['native'] = yield select((state: RootState) => state.native);
        if (lastState !== nextState) {
            yield localforage.setItem(NATIVE_STATE_KEY, nextState);
            lastState = nextState;
        }
    }
}

export function* nativeSagas() {
    if (!isNative) return;

    yield all([spawn(syncNativeState)]);
}
