import type { BarLength, PointInTime, SlidingWindow, Timeframe } from './timeframe';

export enum FilterSymbol {
    '==' = '==',
    '(' = '(',
    ')' = ')',
    'like' = 'like',
    '<' = '<',
    '>' = '>',
    '<=' = '<=',
    '!=' = '!=',
    '>=' = '>=',
    '+' = '+',
    '-' = '-',
    ',' = ',',
    'or' = 'or',
    '*' = '*',
    '/' = '/',
    '%' = '%',
    '.' = '.',
    'and' = 'and',
    '^' = '^',
}
export enum TimeMathOperator {
    '+' = '+',
    '-' = '-',
}
export enum NumericMathOperator {
    '+' = '+',
    '-' = '-',
    '*' = '*',
    '/' = '/',
    '%' = '%',
    '^' = '^',
}
export enum LookupOperator {
    '.' = '.',
}
export enum BinaryLogicalOperator {
    or = 'or',
    and = 'and',
}
export enum NumericComparisonOperator {
    '==' = '==',
    '!=' = '!=',
    '<' = '<',
    '<=' = '<=',
    '>' = '>',
    '>=' = '>=',
}
export enum TimeComparisonOperator {
    '<' = '<',
    '>' = '>',
}
export enum EnumComparisonOperator {
    '==' = '==',
    '!=' = '!=',
}
export enum GroupingSymbol {
    '(' = '(',
    ')' = ')',
}
export enum StringComparisonOperator {
    'like' = 'like',
    '==' = '==',
    '!=' = '!=',
    '<' = '<',
    '<=' = '<=',
    '>' = '>',
    '>=' = '>=',
}
export enum StringOperationOperator {
    '+' = '+',
}
export type ComparisonOperator = NumericComparisonOperator | StringComparisonOperator;
export type BinaryOperator =
    | NumericMathOperator
    | NumericComparisonOperator
    | TimeMathOperator
    | TimeComparisonOperator
    | StringComparisonOperator
    | BinaryLogicalOperator
    | LookupOperator
    | EnumComparisonOperator
    | StringOperationOperator;
export enum UnaryOperator {
    '-' = '-',
    '+' = '+',
}
export enum OperatorType {
    logical = 'logical',
    comparison = 'comparison',
    math = 'math',
    unknown = 'unknown',
}
export type Range = {
    start: number;
    end: number;
};
export type AllowedValueType = string | number | Timeframe | SlidingWindow | PointInTime | BarLength | boolean;
export type AllowedValue = {
    value: AllowedValueType;
};
export type ParameterDataType = { index: number };
export type SeriesDataType = { series: ConcreteDataType; default?: PointInTime };
export type FieldDataType =
    | { type: FieldTypeCategory.Double | FieldTypeCategory.Integer; unsigned?: boolean }
    | { type: FieldTypeCategory.PointInTime; relativeOnly?: boolean }
    | { type: FieldTypeCategory.Bitmask }
    | { type: FieldTypeCategory.Boolean }
    | { type: FieldTypeCategory.Duration }
    | { type: FieldTypeCategory.Enum }
    | { type: FieldTypeCategory.Ignored }
    | { type: FieldTypeCategory.Index }
    | { type: FieldTypeCategory.Periodicity }
    | { type: FieldTypeCategory.Regex }
    | { type: FieldTypeCategory.String }
    | { type: FieldTypeCategory.Timeframe }
    | { type: FieldTypeCategory.Timestamp };
export type ConcreteDataType = FieldTypeCategory | FieldDataType | SeriesDataType;
export type DataType = ConcreteDataType | ParameterDataType;

export const isParameterTyped = (dt: DataType): dt is ParameterDataType => typeof dt === 'object' && 'index' in dt;
export const isSeriesTyped = (dt: DataType): dt is SeriesDataType => typeof dt === 'object' && 'series' in dt;
export const isFieldTyped = (dt: DataType): dt is FieldDataType =>
    typeof dt === 'string' || (typeof dt === 'object' && 'type' in dt);

export interface FunctionDef {
    id: string;
    symbol: string;
    /** An array of parameter arrays, used to construct overloads of allowable parameter types */
    params: ParameterDef[];
    returnType: DataType;
}
export interface ParameterDef {
    name?: string;
    dataType: ConcreteDataType;
    optional?: boolean;
    defaultValue?: AllowedValueType;
    allowedValues?: AllowedValue[];
}
export enum TokenType {
    Error = 'error',
    Symbol = 'symbol',
    String = 'string',
    Regex = 'regex',
    Name = 'name',
    Number = 'number',
    Whitespace = 'ws',
}
export interface Token {
    range: Range;
    token: string | FilterSymbol;
    type: TokenType;
    error?: string;
}
export type Tokens = string;
export enum FieldTypeCategory {
    Double = 'double',
    Integer = 'int',
    String = 'string',
    Timestamp = 'timestamp',
    Enum = 'enum',
    Bitmask = 'bitmask',
    Boolean = 'boolean',
    Index = 'index',
    Regex = 'regex',
    Timeframe = 'timeframe',
    Periodicity = 'periodicity',
    PointInTime = 'pointInTime',
    Duration = 'duration',
    Ignored = 'ignored',
}
export enum NumericUnit {
    percent = 'percent',
    volume = 'volume',
    price = 'price',
    rank = 'rank',
    quantity = 'quantity',
}
export enum StringUnit {
    symbol = 'symbol',
    name = 'name',
    account = 'account',
    position = 'position',
}
export type ParameterUnit = {
    index: number;
};
export type ConcreteUnit = NumericUnit | StringUnit;
export type Unit = ConcreteUnit | ParameterUnit;
