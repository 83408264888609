/*
import { all, takeEvery, select } from 'redux-saga/effects';
import { RootState } from 'src/store';

function* setTheme() {
    const theme: RootState['theme']['theme'] = yield select((state: RootState) => state.theme.theme);

    localStorage.setItem('theme', theme);
}
*/

export function* themeSagas() {
    //yield all([takeEvery(['theme::set'], setTheme)]);
}
