import { setCommonSuggestions } from '../../actions/askAlpha';
import { waitForAuth } from '../auth/helpers';
import { all, call, put } from 'redux-saga/effects';
import { firstValueFrom } from 'rxjs';
import { commonNlpExamples$ } from 'src/lib/nlp';
import type { NlpSuggestion } from 'src/lib/nlp/contracts';

function* loadDefaultSuggestions() {
    yield* waitForAuth();

    const defaultSuggestions: { data?: NlpSuggestion[] } = yield call(firstValueFrom, commonNlpExamples$);

    if (defaultSuggestions.data) {
        yield put(setCommonSuggestions(defaultSuggestions.data));
    }
}

export function* askAlphaSagas() {
    yield all([loadDefaultSuggestions()]);
}
