export type ChangeTimeSeriesSymbolAction = { type: 'changeTimeSeriesSymbol'; tabId: string; symbol: string };
export const changeTimeSeriesSymbol = (tabId: string, symbol: string): ChangeTimeSeriesSymbolAction => ({
    tabId,
    symbol,
    type: 'changeTimeSeriesSymbol',
});

export type UpdateTimeSeriesSymbolAction = { type: 'updateTimeSeriesSymbol'; tabId: string; symbol: string };
export const updateTimeSeriesSymbol = (tabId: string, symbol: string): UpdateTimeSeriesSymbolAction => ({
    tabId,
    symbol,
    type: 'updateTimeSeriesSymbol',
});
export type TimeSeriesActions = UpdateTimeSeriesSymbolAction | ChangeTimeSeriesSymbolAction;
