import { initialize } from 'launchdarkly-js-client-sdk';
import { TypedContainerModule, type TypedContainer } from 'src/features/ioc/TypedContainer';
import type { StaticBindings, IsomorphicBindings } from 'src/ioc/types';

export const launchDarklyBrowserModule = new TypedContainerModule<StaticBindings & IsomorphicBindings>((bind) => {
    type Container = TypedContainer<StaticBindings & IsomorphicBindings>;

    bind('LaunchDarklyClient').toDynamicValue((ctx) => {
        const configService = (ctx.container as unknown as Container).get('ConfigService');
        const launchDarklyBrowserClient = initialize(configService.launchDarklyConfig.clientSideId, {
            kind: 'user',
            key: 'anonymous',
        });

        return launchDarklyBrowserClient;
    });
});
