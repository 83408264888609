import type { NewWorkspace } from 'src/contracts/workspace';

export const DEFAULT_WIDGET_ACTIVE_BORDER_COLOR = '#4FF4FF';

export const defaultWorkspace: NewWorkspace = {
    name: 'New Workspace',
    containers: [],
    layoutSettings: { containerLayoutLocked: false, gridSnappingEnabled: true },
    description: '',
};

export const makeNamedDefaultWorkspace = (name: string): NewWorkspace => ({
    ...defaultWorkspace,
    name,
});
