import type { RootState } from '..';
import type { WorkspaceViewModel } from '../types';

export const getCurrentlyVisibleWorkspaceId = (state: RootState): string | undefined =>
    state.workspace.currentWorkspace?.id;

export const getCurrentWorkspace = (state: RootState): WorkspaceViewModel | null => state.workspace.currentWorkspace;

export const getAssertedCurrentWorkspace = (state: RootState): WorkspaceViewModel => {
    const currentWorkspace = state.workspace.currentWorkspace;

    if (!currentWorkspace) {
        throw new Error('No active workspace');
    }

    return currentWorkspace;
};

export const getWorkspaceIsResearching =
    () =>
    (state: RootState): boolean =>
        state.workspace.currentWorkspaceStatus === 'pending';
