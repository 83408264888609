import type { NlpSuggestion } from 'src/lib/nlp/contracts';

export type SetCommonSuggestionsAction = { type: 'askAlpha::setCommonSuggestions'; suggestions: NlpSuggestion[] };

export const setCommonSuggestions = (suggestions: NlpSuggestion[]): SetCommonSuggestionsAction => ({
    suggestions,
    type: 'askAlpha::setCommonSuggestions',
});

export type AskAlphaAction = SetCommonSuggestionsAction;
