import type { LocatePreferences } from 'src/contracts/user-preference';

export type SetLocatePreferences = {
    type: 'locates::setLocatePreferences';
    locatePreferences: LocatePreferences;
};
export const setLocatePreferences = (locatePreferences: LocatePreferences): SetLocatePreferences => {
    return {
        type: 'locates::setLocatePreferences',
        locatePreferences,
    };
};

export type ChangeLocateWarningPreferencesAction = {
    type: 'locatePreferences::changeLocateWarningPreferences';
    warningPreferences: Partial<LocatePreferences['warningPreferences']>;
    forever?: boolean;
};
export const changeLocateWarningPreferences = (
    warningPreferences: Partial<LocatePreferences['warningPreferences']>,
    forever?: boolean,
): ChangeLocateWarningPreferencesAction => {
    return {
        type: 'locatePreferences::changeLocateWarningPreferences',
        warningPreferences,
        forever,
    };
};

export type LocatePreferencesAction = SetLocatePreferences | ChangeLocateWarningPreferencesAction;
