import type { LocatesSocketConnectionSettings } from './locates';
import type { Action } from 'redux';
import type { EventChannel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import { Decoder, Encoder } from 'socket.io-parser';
import { reviver } from 'src/lib/serializer';

type ChannelPayload<C extends EventChannel<Record<string, never>>> = C extends EventChannel<infer P> ? P : unknown;

/**
 * Handle channel events as signals for action dispatch
 * @param channel channel to handle
 * @param actionCreator function which will map the tuple event payload to an Action
 * @param completeActionCreator optonal action to create and emit when channel closes
 */
export function* handleChannel<C extends EventChannel<Record<string, never>>>(
    channel: C,
    actionCreator: (e: ChannelPayload<C>) => Action | undefined,
    completeActionCreator?: () => Action,
) {
    try {
        while (true) {
            // take(END) will cause the saga to terminate by jumping to the finally block
            const payload: ChannelPayload<C> = yield take(channel);
            const action = actionCreator(payload);
            if (action) {
                yield put(action);
            }
        }
    } finally {
        const completeAction = completeActionCreator?.();
        if (completeAction) {
            yield put(completeAction);
        }
    }
}

export function createLocatesSocket({ uri }: LocatesSocketConnectionSettings): Socket {
    return io(uri, {
        timeout: 10000,
        transports: ['websocket'],
        parser: {
            Encoder: Encoder,
            Decoder: class extends Decoder {
                constructor() {
                    super(reviver);
                }
            },
        },
        reconnectionDelay: 500, // start at half a second
        reconnectionDelayMax: 60000, // one minute for max delay
        randomizationFactor: 0.1, // randomize by 10%
    });
}
