import type { SearchAlphaWidgetViewModel } from '../types';
import { IfThenPartType } from 'src/contracts/if-then';
import { BinaryLogicalOperator } from 'src/contracts/language';
import { StrategyType } from 'src/contracts/strategy';

export const createDefaultSearchAlphaWidget = (
    values: Partial<SearchAlphaWidgetViewModel> = {},
): SearchAlphaWidgetViewModel => {
    return {
        type: 'search-alpha',
        channelId: null,
        displayingResults: true,
        displayingSearchForm: true,
        displayingIdeas: true,
        splitPercentage: 50,
        filter: null,
        universe: null,
        columnTemplate: null,
        scrolling: false,
        strategy: {
            name: null,
            id: undefined,
            plan: {
                key: 'ticker',
                formula: '',
                root: {
                    type: IfThenPartType.group,
                    id: 'indicator',
                    collapsed: false,
                    description: null,
                    enabled: true,
                    name: null,
                    lines: [],
                    operator: BinaryLogicalOperator.and,
                },
                imports: [],
            },
            type: StrategyType.formula,
            defaultColumnTemplateId: null,
            defaultUniverseId: null,
            description: null,
            isTemplate: false,
        },
        ...values,
    };
};
