import type { SocketEmitActions, SocketEventKeys, SocketInboundEventMap } from '../actions/socket';
import { socketEmitError } from '../actions/socket';
import type { IOSocketEvent } from '../types/socket';
import { isIOSocketEvent } from '../types/socket';
import { handleChannel } from './util';
import type { Action } from 'redux';
import type { EventChannel } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import type { Socket } from 'socket.io-client';

/**
 * Creates a channel which will listen for and emit 'connect' events, and will close
 * when the socket disconnects
 * @param socket socket to bind to
 * @returns EventChannel with lifetime tied to the Socket connection
 */
export function createSocketConnectChannel(socket: Socket): EventChannel<Record<string, never>> {
    return eventChannel((emitter) => {
        const handler = () => emitter({});
        // TODO: don't ignore error metadata

        // default listener
        socket.on('connect', handler);

        return () => {
            socket.off('connect', handler);
        };
    });
}

/**
 * Creates a saga which will allow calling .emit on a socket using a redux Action
 * @param socket socket to emit into
 * @returns a saga which will handle uniform emit action for the socket
 */
export function handleEmissions<S extends Socket>(socket: S) {
    return function* (message: SocketEmitActions<S>) {
        try {
            yield call(
                {
                    context: socket,
                    fn: socket.emit,
                },
                message.type,
                ...message.payload,
            );
        } catch (error) {
            if (error instanceof Error) {
                yield put(socketEmitError(error));
            }
        }
    };
}

/**
 * Creates a channel which wraps socket.io incoming event subscriptions
 * @param socket socket to observe for event payloads
 * @param event name of the event to listen for
 * @param useIO true will use socket.io.on, false/undefine will use socket.on
 * @returns an EventChannel which will receive the raw event payloads in tuple format
 */
export function createSocketEventChannel<
    S extends Socket,
    E extends string & SocketEventKeys<SocketInboundEventMap<S>>,
    P extends Parameters<SocketInboundEventMap<S>[E]>,
>(socket: S, event: E, useIO?: boolean): EventChannel<P> {
    return eventChannel((emitter) => {
        const handler = (...args: P) => emitter(args);

        if (useIO) {
            socket.io.on(event as IOSocketEvent, handler as any);
        } else socket.on(event, handler as any);
        return () => socket.off(event, handler as any);
    });
}

/**
 * Handle Socket events as signals for Action dispatch
 * @param socket socket to observe for event payloads
 * @param event name of the event to listen to
 * @param actionCreator function which will map the tuple event payload to an Action
 */
export function* handleSocket<
    S extends Socket,
    E extends string & SocketEventKeys<SocketInboundEventMap<S>>,
    P extends Parameters<SocketInboundEventMap<S>[E]>,
>(socket: S, event: E, actionCreators: ((...payload: P) => Action | undefined)[]) {
    if (actionCreators.length === 0) return;

    const generators: Generator[] = [];
    const channel = createSocketEventChannel(socket, event, isIOSocketEvent(event));

    actionCreators.forEach((actionCreator) => {
        const payloadSpreader = (e: P) => actionCreator(...e);
        generators.push(handleChannel(channel as any, payloadSpreader));
    });

    yield all(generators);
}
