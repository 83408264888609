import type { WidgetTabViewModel } from '../types';
import type { WidgetCreationInfo } from 'src/contracts/workspace';

export type UpdateTabNameAction = {
    type: 'updateTabName';
    tabId: string;
    tabName: string;
};

export const updateTabName = (tabId: string, tabName: string): UpdateTabNameAction => ({
    tabId,
    tabName,
    type: 'updateTabName',
});

export type AddTabAction = {
    type: 'addTab';
    tab: WidgetTabViewModel;
    containerId: string;
};

export const addTab = (tab: WidgetTabViewModel, containerId: string): AddTabAction => ({
    type: 'addTab',
    tab,
    containerId,
});

export type AddNewWidgetToContainerAction = {
    type: 'addNewWidgetToContainer';
    tab: WidgetCreationInfo;
    containerId: string;
};

export const addNewWidgetToContainer = (
    tab: WidgetCreationInfo,
    containerId: string,
): AddNewWidgetToContainerAction => ({
    containerId,
    tab,
    type: 'addNewWidgetToContainer',
});

export type RemoveTabAction = {
    type: 'removeTab';
    tabId: string;
    containerId: string;
    newActiveTabId: string;
};

export const removeTab = (tabId: string, containerId: string, newActiveTabId: string): RemoveTabAction => ({
    type: 'removeTab',
    tabId,
    containerId,
    newActiveTabId,
});

export type UpdateActiveTabAction = {
    type: 'updateActiveTab';
    containerId: string;
    tabId: string;
};

export const updateActiveTab = (containerId: string, tabId: string): UpdateActiveTabAction => ({
    type: 'updateActiveTab',
    containerId,
    tabId,
});

export type MoveTabAction = {
    type: 'moveTab';
    containerId: string;
    startIndex: number;
    endIndex: number;
};

/**
 * Moves a tab from the startIndex to the endIndex
 * @param startIndex the position of the tab that is being dragged
 * @param endIndex the position of the tab that the dragged tab is being dropped on
 */
export const moveTab = (containerId: string, startIndex: number, endIndex: number): MoveTabAction => ({
    type: 'moveTab',
    containerId,
    startIndex,
    endIndex,
});

export type TabAction =
    | AddTabAction
    | AddNewWidgetToContainerAction
    | MoveTabAction
    | RemoveTabAction
    | UpdateActiveTabAction
    | UpdateTabNameAction;
