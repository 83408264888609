export type IOSocketEvent =
    | 'reconnect'
    | 'reconnect_attempt'
    | 'reconnect_error'
    | 'open'
    | 'close'
    | 'error'
    | 'packet'
    | 'ping';

export const isIOSocketEvent = (event: string): event is IOSocketEvent => {
    return (
        event === 'reconnect' ||
        event === 'reconnect_attempt' ||
        event === 'reconnect_error' ||
        event === 'open' ||
        event === 'close' ||
        event === 'error' ||
        event === 'packet' ||
        event === 'ping'
    );
};
