import type { RootState } from '..';
import type { ToggleAdminStatus } from '../actions/admin';
import { setSuperuserAccessEnabledUntil } from '../actions/admin';
import { Duration, Instant } from '@js-joda/core';
import { all, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { SUPERUSER_ACCESS_EXPIRATION_KEY } from 'src/api';
import { localforage } from 'src/lib/serialization/localForage';

const ADMIN_MODE_EXPIRATION_DELAY = Duration.ofMinutes(15);
const ADMIN_MODE_DEV_EXPIRATION_DELAY = Duration.ofDays(365);
const ADMIN_MODE_CHECK_INTERVAL = Duration.ofSeconds(15);

export function* toggleAdminStatus({ adminModeEnabled }: ToggleAdminStatus) {
    if (adminModeEnabled) {
        const isDev: boolean = yield select((state: RootState) => state.auth.user?.isDeveloper || false);
        const expirationTimestamp = Instant.now().plus(
            isDev ? ADMIN_MODE_DEV_EXPIRATION_DELAY : ADMIN_MODE_EXPIRATION_DELAY,
        );
        yield localforage.setItem(SUPERUSER_ACCESS_EXPIRATION_KEY, expirationTimestamp);
        yield put(setSuperuserAccessEnabledUntil(expirationTimestamp));
    } else {
        yield localforage.removeItem(SUPERUSER_ACCESS_EXPIRATION_KEY);
        yield put(setSuperuserAccessEnabledUntil(undefined));
    }
}

export function* onRunSuperuserAccessCheck() {
    const isDev: boolean = yield select((state: RootState) => state.auth.user?.isDeveloper || false);
    if (isDev) {
        return;
    }

    let superuserAccessEnabledUntil: RootState['ui']['superuserAccessEnabledUntil'] = yield select(
        (state: RootState) => state.ui.superuserAccessEnabledUntil,
    );
    if (!superuserAccessEnabledUntil) {
        return;
    }

    while (superuserAccessEnabledUntil && superuserAccessEnabledUntil.isAfter(Instant.now())) {
        const { checkDelay, setStatusFromUi }: { checkDelay?: true; setStatusFromUi?: ToggleAdminStatus } = yield race({
            checkDelay: delay(ADMIN_MODE_CHECK_INTERVAL.toMillis()),
            setStatusFromUi: take('superuser::toggle'),
        });

        if (!checkDelay && setStatusFromUi) {
            if (setStatusFromUi.adminModeEnabled === false) {
                superuserAccessEnabledUntil = undefined;
            } else {
                superuserAccessEnabledUntil = yield select((state: RootState) => state.ui.superuserAccessEnabledUntil);
            }
        }
    }

    yield localforage.removeItem(SUPERUSER_ACCESS_EXPIRATION_KEY);
    yield put(setSuperuserAccessEnabledUntil(undefined));
}

export function* adminSagas() {
    // ToDo: declaration merging on ActionPattern so that we can more easily type that
    yield all([
        takeLatest('superuser::set-expiration', onRunSuperuserAccessCheck),
        takeLatest('superuser::toggle', toggleAdminStatus),
    ]);
}
