import type { EventsMap } from '@socket.io/component-emitter';
import type { Socket } from 'socket.io-client';

export type SocketInboundEventMap<S extends Socket> = S extends Socket<infer Inbound, any> ? Inbound : never;
export type SocketOutboundEventMap<S extends Socket> = S extends Socket<any, infer Outbound> ? Outbound : never;

/**
 * @internal
 */
type AllEventActions<E extends EventsMap> = {
    [K in keyof E]: {
        type: K;
        payload: Parameters<E[K]>;
    };
};

/**
 * @internal
 */
type SocketEventAction<E extends EventsMap> = AllEventActions<E>[keyof AllEventActions<E>];

export type SocketEventKeys<E extends EventsMap> = keyof AllEventActions<E>;

// TODO: Somehow using conditional type strips 'number' | 'symbol' from keyof and makes it
// pass socket.io .emit validation; however it should refer to the intermediate type:
//     export type SocketEmitActions<S extends Socket> = SocketEventAction<SocketOutboundEventMap<S>>;
//
export type SocketEmitActions<S extends Socket> =
    S extends Socket<any, infer Outbound> ? SocketEventAction<Outbound> : unknown;

export type SocketListenActions<S extends Socket> =
    S extends Socket<infer Inbound, any> ? SocketEventAction<Inbound> : unknown;

export interface SocketEmitErrorEvent {
    type: 'socket::emit::error';
    payload: {
        error: string;
    };
}

export const socketEmitError = (e: Error): SocketEmitErrorEvent => ({
    type: 'socket::emit::error',
    payload: {
        error: e.message,
    },
});
