export type UpdateLocatesSymbolAction = { type: 'updateLocatesSymbol'; tabId: string; symbol: string };
export const updateLocatesSymbol = (tabId: string, symbol: string): UpdateLocatesSymbolAction => ({
    tabId,
    symbol,
    type: 'updateLocatesSymbol',
});

export type UserSelectedNewLocatesSymbolAction = {
    type: 'userSelectedNewLocatesSymbol';
    symbol: string;
    tabId: string;
};

export const userSelectedNewLocatesSymbol = (tabId: string, symbol: string): UserSelectedNewLocatesSymbolAction => ({
    symbol,
    tabId,
    type: 'userSelectedNewLocatesSymbol',
});

export type UpdateLocatesAccountAction = {
    type: 'updateLocatesAccount';
    tabId: string;
    accountId: string | null;
};
export const updateLocatesAccount = (tabId: string, accountId: string): UpdateLocatesAccountAction => ({
    tabId,
    accountId,
    type: 'updateLocatesAccount',
});

export type LocatesActions =
    | UserSelectedNewLocatesSymbolAction
    | UpdateLocatesSymbolAction
    | UpdateLocatesAccountAction;
