import { rxapi, api } from '../api';
import type {
    NewWorkspace,
    UpdatedWorkspace,
    Workspace,
    WorkspaceConfig,
    WorkspaceRecord,
} from '../contracts/workspace';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { IndicatorImportRefViewModel } from 'src/contracts/dictionary-view-model';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import type { Strategy } from 'src/contracts/strategy';
import { container } from 'src/ioc/StaticContainer';
import { processQuery } from 'src/lib/paging';
import type {
    ContainerLayoutViewModel,
    ContainerViewModel,
    WidgetTabViewModel,
    WorkspaceViewModel,
} from 'src/store/types';
import {
    createWorkspaceViewModel,
    createContainerLayoutViewModel,
    createContainerViewModel,
    generateWidgetTabViewModels,
} from 'src/store/types/workspaceViewModels';

interface WorkspaceDimensions {
    columnCount: number;
    rowCount: number;
}

interface MaybeWithDimensions {
    dimensions?: WorkspaceDimensions;
}

const addDimensionsToWorkspace = (
    workspace: (UpdatedWorkspace | NewWorkspace) & MaybeWithDimensions,
): (UpdatedWorkspace | NewWorkspace) & MaybeWithDimensions => {
    if (!('dimensions' in workspace)) {
        return {
            ...workspace,
            dimensions: {
                columnCount: 1,
                rowCount: 1,
            },
        };
    } else {
        return workspace;
    }
};

const mapToCurrentUIExpectations = (workspace: Workspace & MaybeWithDimensions): Workspace => {
    const { dimensions, ...rest } = workspace;

    if (dimensions && (dimensions.columnCount !== 1 || dimensions?.rowCount !== 1)) {
        rest.containers = rest.containers.map((container) => {
            const { layout, ...rest } = container;
            return {
                ...rest,
                layout: {
                    ...layout,
                    h: layout.h / dimensions.rowCount,
                    w: layout.w / dimensions.columnCount,
                    x: layout.x / dimensions.columnCount,
                    y: layout.y / dimensions.rowCount,
                },
            };
        });
    }

    // hack time, strip formData and displayMode from simple order entry and order entry widgets
    // TODO remove once backend is updated to no longer return formData on these widgets
    rest.containers = rest.containers.map((container) => {
        const { tabs, ...rest } = container;
        return {
            ...rest,
            tabs: tabs.map((tab) => {
                if (tab.widget.type === 'simple-order-entry' || tab.widget.type === 'order-entry') {
                    delete tab.widget.formData;
                    delete tab.widget.displayMode;
                }
                return tab;
            }),
        };
    });

    return rest;
};

export async function getDefaultWorkspace(expandImports?: true): Promise<Workspace> {
    const res = await api
        .get<Workspace>(`/workspaces/default${expandImports ? '?$expand=imports' : ''}`)
        .then((res) => mapToCurrentUIExpectations(res.data));
    shittyHackWorkspaceMap(res);
    return res;
}

export async function getWorkspaceById(id: string): Promise<Workspace> {
    const workspace = await api
        .get<Workspace>(`/workspaces/${id}?$expand=imports}`)
        .then((res) => mapToCurrentUIExpectations(res.data));

    // this is an ugly hack. cover your eyes.
    shittyHackWorkspaceMap(workspace);

    return workspace;
}

function shittyHackWorkspaceMap(workspace: Workspace) {
    for (const container of workspace.containers) {
        for (const tab of container.tabs) {
            if (tab.widget.type === 'strategy' && tab.widget.displayMode === 'watchlist') {
                tab.widget.type = 'watchlist';
            }
        }
    }
    return workspace;
}

export function createWorkspace(workspace: NewWorkspace): Observable<Workspace> {
    return rxapi
        .post<Workspace>(`/workspaces?$expand=imports`, addDimensionsToWorkspace(workspace))
        .pipe(map((x) => mapToCurrentUIExpectations(x.data)));
}

export function saveWorkspace(workspace: UpdatedWorkspace, expandImports?: true): Observable<Workspace> {
    return rxapi
        .put<Workspace>(`/workspaces/${workspace.id}${expandImports ? '?$expand=imports' : ''}`, {
            ...addDimensionsToWorkspace(workspace),
            channels: [],
        })
        .pipe(map((x) => mapToCurrentUIExpectations(x.data)));
}

export function deleteWorkspace(id: string): Observable<void> {
    return rxapi.delete(`/workspaces/${id}`).pipe(
        map(() => {
            /* void */
        }),
    );
}

export function getWorkspacesQuery(query: ResourceQuery): Observable<ResourceQueryResponseWithMeta<WorkspaceRecord>> {
    return rxapi.get(`/workspaces/query${processQuery({ ...query, includeMeta: true })}`).pipe(map((x) => x.data));
}
export function getWorkspacesCountQuery(): Observable<ResourceQueryResponseWithMeta<WorkspaceRecord>> {
    return rxapi.get(`/workspaces/query?$select=&$count=true`).pipe(map((x) => x.data));
}

export function getWorkspaceConfig(): Promise<WorkspaceConfig> {
    return api.get('/workspaces/config').then((res) => res.data);
}

export async function getTemplateWorkspaces(expandImports?: true): Promise<Workspace[]> {
    const res = await api.get<Workspace[]>(`/workspaces/templates${expandImports ? '?$expand=imports' : ''}`);
    return res.data.map(mapToCurrentUIExpectations).map(shittyHackWorkspaceMap);
}

type ExplodedWorkspaceViewModel = {
    workspaceViewModel: WorkspaceViewModel;
    layoutViewModels: ContainerLayoutViewModel[];
    containerViewModels: ContainerViewModel[];
    widgetTabViewModels: WidgetTabViewModel[];
    importRefs: IndicatorImportRefViewModel[];
};

export async function getWorkspaceViewModelSetup(workspaceId: 'default' | string): Promise<ExplodedWorkspaceViewModel> {
    // Remove double-fetch of default and current workspaces when we get correct default workspace information from BFFE

    const queryClient = container.get('QueryClient');
    const defaultWorkspaceP = queryClient.fetchUserQuery({
        queryFn: () => getDefaultWorkspace(true).catch(() => null),
        queryKey: ['workspaces', 'default'],
        // TODO: Cache-invalidate default workspace query when any workspace is saved
        gcTime: 0,
    });
    const workspaceP =
        workspaceId === 'default'
            ? defaultWorkspaceP
            : queryClient.fetchUserQuery({
                  queryFn: () => getWorkspaceById(workspaceId).catch(() => null),
                  queryKey: ['workspaces', workspaceId],
                  // TODO: Cache-invalidate default workspace query when any workspace is saved
                  gcTime: 0,
              });

    const [defaultWorkspace, workspace] = await Promise.all([defaultWorkspaceP, workspaceP]);

    if (!workspace) {
        throw new Error('Workspace not found');
    }

    // Perform research on all indicators stored here
    const containerStrategies = workspace.containers
        .flatMap((x) => x.tabs)
        .flatMap((x) => {
            const { widget } = x;
            if (widget.type === 'if-then-builder') {
                if (widget.strategy && typeof widget.strategy !== 'string') {
                    return [widget.strategy];
                }
            } else if (widget.type === 'strategy') {
                return widget.strategies?.filter((x) => x && typeof x !== 'string') ?? [];
            }

            return [];
        });

    const allImportRefs = [...containerStrategies]
        // Todo: why do i have to explicitly type guard this one? it should be implicit from typeof x !== 'string
        .filter((x): x is Strategy<IndicatorImportRefViewModel> => typeof x !== 'string')
        .flatMap((x) => x.plan.imports);

    // Remove additional argument for default workspace ID when we get correct default workspace information from BFFE
    const workspaceViewModel = createWorkspaceViewModel(workspace, workspace.id === defaultWorkspace?.id);

    const layoutViewModels = workspace.containers.map<ContainerLayoutViewModel>((container) =>
        createContainerLayoutViewModel(container.layout, container.id),
    );

    const containerViewModels = workspace.containers.map<ContainerViewModel>((container) =>
        createContainerViewModel(container),
    );

    const widgetTabViewModels: WidgetTabViewModel[] = await generateWidgetTabViewModels(workspace);

    return {
        workspaceViewModel,
        layoutViewModels,
        widgetTabViewModels,
        containerViewModels,
        importRefs: allImportRefs,
    };
}
