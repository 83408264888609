import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import type { Layout, WidgetContainer, WidgetTab } from 'src/contracts/workspace';
import { getWidgetDisplayName } from 'src/features/widget/model/widget';
import type { StaticBindings } from 'src/ioc/types';
import type { RootState } from 'src/store';
import type { AddWidgetToDashboardAction } from 'src/store/actions/container';
import { addContainer } from 'src/store/actions/container';
import { type AddNewWidgetToContainerAction, addNewWidgetToContainer, addTab } from 'src/store/actions/tab';
import { getWidgetFromCreationInfo } from 'src/store/sagas/widgets/construction/create-widget';
import type { SagaContext, WidgetTabViewModel } from 'src/store/types';
import {
    createContainerLayoutViewModel,
    createContainerViewModel,
    mapWidgetTabToViewModel,
} from 'src/store/types/workspaceViewModels';
import { v4 as uuid } from 'uuid';

const DEFAULT_LAYOUT = {
    x: 0.2,
    y: 0.4,
    w: 0.6,
    h: 0.2,
};

const ALPHA_LENS_LAYOUT = {
    h: 0.8,
    w: 0.33,
    x: 0.33,
    y: 0.1,
};

const SEARCH_ALPHA_LAYOUT = {
    h: 0.5,
    w: 0.6,
    x: 0.2,
    y: 0.05,
};

export function* addWidgetToDashboardSaga(action: AddWidgetToDashboardAction) {
    const currentlyMaximizedContainer: string | undefined = yield select(
        (store: RootState) => store.container.maximizedContainerId,
    );

    if (currentlyMaximizedContainer) {
        yield put(addNewWidgetToContainer(action.widget, currentlyMaximizedContainer));
        return;
    }

    let layoutToUse = DEFAULT_LAYOUT;

    const widgetCreationInfo = action.widget;

    const ctx: SagaContext['container'] = yield getContext('container');
    const t: Awaited<ReturnType<StaticBindings['TProvider']>> = yield call(() => ctx.get('TProvider')());

    const initialWidget = yield* getWidgetFromCreationInfo(action.widget);

    const widgetDisplayName = getWidgetDisplayName(t, initialWidget);

    const initialWidgetTab = {
        id: uuid(),
        name: widgetDisplayName,
        widget: initialWidget,
    };

    switch (widgetCreationInfo.creationType) {
        case 'from-widget-default': {
            if (widgetCreationInfo.widgetType === 'alpha-lens') {
                layoutToUse = ALPHA_LENS_LAYOUT;
            }
            if (widgetCreationInfo.widgetType === 'search-alpha') {
                layoutToUse = SEARCH_ALPHA_LAYOUT;
            }

            break;
        }
        case 'new-alpha-lens': {
            layoutToUse = ALPHA_LENS_LAYOUT;
            break;
        }

        case 'cloned': {
            const clonedLayout: Layout = yield select(
                (store: RootState) => store.layout.byContainerId[widgetCreationInfo.baseWidgetContainerId],
            );
            const { x, y, ...rest } = clonedLayout;
            layoutToUse = {
                x: x + 0.01,
                y: y + 0.025,
                ...rest,
            };

            break;
        }
    }

    const newContainer: WidgetContainer = {
        id: uuid(),
        tabs: [initialWidgetTab],
        layout: layoutToUse,
        activeTabId: initialWidgetTab.id,
    };

    const layoutViewModel = createContainerLayoutViewModel(newContainer.layout, newContainer.id);

    const containerViewModel = createContainerViewModel(newContainer);
    const initialTabViewModel: WidgetTabViewModel = yield call(
        mapWidgetTabToViewModel,
        newContainer.id,
        newContainer.tabs[0].id,
        newContainer.tabs[0],
    );

    yield put(addContainer(containerViewModel, initialTabViewModel, layoutViewModel));
}

export function* createWidgetTabSaga(action: AddNewWidgetToContainerAction) {
    const { tab, containerId } = action;

    const widget = yield* getWidgetFromCreationInfo(tab);

    const ctx: SagaContext['container'] = yield getContext('container');
    const t: Awaited<ReturnType<StaticBindings['TProvider']>> = yield call(() => ctx.get('TProvider')());

    const widgetDisplayName = getWidgetDisplayName(t, widget);

    const widgetTab: WidgetTab = {
        id: uuid(),
        name: widgetDisplayName,
        widget,
    };

    const tabViewModel: WidgetTabViewModel = yield call(mapWidgetTabToViewModel, containerId, widgetTab.id, widgetTab);

    yield put(addTab(tabViewModel, containerId));
}

export function* widgetConstructionSagas() {
    yield all([
        takeLatest('addWidgetToDashboard', addWidgetToDashboardSaga),
        takeLatest('addNewWidgetToContainer', createWidgetTabSaga),
    ]);
}
