import { rxapi, api } from '../api';
import { randomString } from '../util/randomString';
import { processQuery } from './paging';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { TableCreationResult } from 'src/contracts/table';
import type { ConcreteUniverse, NewUniverse, Universe, UniversePatch, UniverseRecord } from 'src/contracts/universe';

export function getUniverses(): Observable<UniverseRecord[]> {
    return rxapi.get<UniverseRecord[]>(`/universes${processQuery({ limit: 250 })}`).pipe(map((x) => x.data));
}

export function getUniverseById(id: string): Observable<ConcreteUniverse | undefined> {
    return rxapi.get<ConcreteUniverse>(`/universes/${id}`).pipe(
        map((x) => x.data),
        // the following is a hack brought to you by the lets get this out the door project of late 2023, the letter Q, and viewers like you
        map((x) => ({
            ...x,
            aspects: x.aspects.map((y) => ({ id: randomString(), ...y })),
        })),
    );
}

export function saveUniverse(universe: NewUniverse | undefined): Observable<ConcreteUniverse> {
    return rxapi.post(`/universes`, universe).pipe(map((x): ConcreteUniverse => x.data));
}

export function patchUniverse(id: string, patch: UniversePatch): Observable<ConcreteUniverse> {
    return rxapi.patch(`/universes/${id}`, patch).pipe(map((x) => x.data));
}

export function playTransientUniverse(universe: Readonly<Universe>): Observable<TableCreationResult> {
    return rxapi.post<TableCreationResult>(`/universes/build`, universe).pipe(map((x) => x.data));
}

export function deleteUniverse(id: string): Promise<ConcreteUniverse> {
    return api.delete(`/universes/${id}`).then((x) => x.data);
}

export function getDefaultUniverse(): Observable<ConcreteUniverse> {
    return rxapi.get<ConcreteUniverse>(`/universes/default`).pipe(
        map((x) => x.data),
        // the following is a hack brought to you by the lets get this out the door project of late 2023, the letter Q, and viewers like you
        map((x) => ({
            ...x,
            aspects: x.aspects.map((y) => ({ id: randomString(), ...y })),
        })),
    );
}

export const saveUniverseQuery = async (newUniverse: NewUniverse): Promise<ConcreteUniverse> => {
    return (await api.post(`/universes`, newUniverse)).data;
};

export const patchUniverseQuery = async (universe: Universe): Promise<ConcreteUniverse> => {
    return (await api.patch(`/universes/${universe.id}`, universe)).data;
};

export const getWatchlistsQuery = async (): Promise<UniverseRecord[]> => {
    return (await api.get<UniverseRecord[]>(`/universes${processQuery({ filter: 'aspects/$count eq 0', limit: 250 })}`))
        .data;
};
