import { type i18n, type KeysWithoutReturnObjects } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { Widget, WidgetType } from 'src/contracts/workspace';

export interface WidgetTypeDTO {
    id: WidgetType;
    nameKey: KeysWithoutReturnObjects['json'];
    roles: string[];
}

export type WidgetNameKey = WidgetTypeDTO['nameKey'];

export const WIDGETS: WidgetTypeDTO[] = [
    {
        id: 'strategy',
        nameKey: 'widgets::strategy::name',
        roles: ['widgets::results'],
    },
    {
        id: 'watchlist',
        nameKey: 'widgets::watchlist::name',
        roles: ['widgets::watchlist'],
    },
    {
        id: 'locates',
        nameKey: 'widgets::locates::name',
        roles: ['widgets::locates'],
    },
    {
        id: 'positions',
        nameKey: 'widgets::positions::name',
        roles: ['widgets::positions'],
    },
    {
        id: 'messages',
        nameKey: 'widgets::messages::name',
        roles: ['widgets::messages'],
    },
    {
        id: 'accounts',
        nameKey: 'widgets::accounts::name',
        roles: ['widgets::accounts'],
    },
    {
        id: 'order-blotter',
        nameKey: 'widgets::order-blotter::name',
        roles: ['widgets::order-blotter'],
    },
    {
        id: 'order-entry',
        nameKey: 'widgets::order-entry::name',
        roles: ['widgets::order-entry'],
    },
    {
        id: 'simple-order-entry',
        nameKey: 'widgets::simple-order-entry::name',
        roles: ['widgets::simple-order-entry'],
    },
    {
        id: 'orders',
        nameKey: 'widgets::orders::name',
        roles: ['widgets::orders'],
    },
    {
        id: 'table',
        nameKey: 'widgets::table::name',
        roles: ['widgets::table'],
    },
    {
        id: 'time-series',
        nameKey: 'widgets::time-series::name',
        roles: ['widgets::time-series'],
    },
    {
        id: 'executions',
        nameKey: 'widgets::executions::name',
        roles: ['widgets::executions'],
    },
    {
        id: 'if-then-builder',
        nameKey: 'widgets::if-then-builder::name',
        roles: ['widgets::if-then-builder'],
    },
    {
        id: 'search-alpha',
        nameKey: 'widgets::search-alpha::name',
        roles: ['widgets::search-alpha'],
    },
    {
        id: 'alpha-lens',
        nameKey: 'widgets::alpha-lens::name',
        roles: ['widgets::alpha-lens'],
    },
    {
        id: 'aggregated-positions',
        nameKey: 'widgets::aggregated-positions::name',
        roles: ['widgets::aggregated-positions'],
    },
    {
        id: 'news-and-events',
        nameKey: 'widgets::news-and-events::name',
        roles: ['widgets::news'],
    },
];
Object.freeze(WIDGETS);

const widgetsById = WIDGETS.reduce(
    (acc, widget) => {
        acc[widget.id] = widget;
        return acc;
    },
    {} as Record<WidgetType, WidgetTypeDTO>,
);
Object.freeze(widgetsById);

export function useWidgetDisplayName(widget: Widget): string {
    const { type } = widget;
    return useTranslation().t(widgetsById[type].nameKey);
}

export function getWidgetDisplayName(t: i18n['t'], widget: Widget): string {
    const { type } = widget;
    return t(widgetsById[type].nameKey);
}
