import type { Instant } from '@js-joda/core';

export type ToggleAdminStatus = { type: 'superuser::toggle'; adminModeEnabled: boolean };

export type SetSuperuserAccessEnabledUntilAction = {
    type: 'superuser::set-expiration';
    superuserAccessEnabledUntil: Instant | undefined;
};
export const setSuperuserAccessEnabledUntil = (
    superuserAccessEnabledUntil: Instant | undefined,
): SetSuperuserAccessEnabledUntilAction => ({
    type: 'superuser::set-expiration',
    superuserAccessEnabledUntil,
});

export type AdminAction = SetSuperuserAccessEnabledUntilAction | ToggleAdminStatus;
