export type UpdateExecutionsSymbolFilterAction = {
    type: 'updateExecutionsSymbolFilter';
    tabId: string;
    symbol: string;
};
export const updateExecutionsSymbolFilter = (tabId: string, symbol: string): UpdateExecutionsSymbolFilterAction => ({
    tabId,
    symbol,
    type: 'updateExecutionsSymbolFilter',
});

export type UpdateExecutionsAccountAction = {
    type: 'updateExecutionsAccount';
    tabId: string;
    accountId: string | null;
};
export const updateExecutionsAccount = (tabId: string, accountId: string): UpdateExecutionsAccountAction => ({
    tabId,
    accountId,
    type: 'updateExecutionsAccount',
});

export type ExecutionsActions = UpdateExecutionsSymbolFilterAction | UpdateExecutionsAccountAction;
