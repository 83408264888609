import { BehaviorSubject } from 'rxjs';
import { useObservableValue } from 'src/hooks/useObservableValue';
import type { LocatesClient } from 'src/lib/locates/interface';
import { LocatesClientImpl as ModernClient } from 'src/lib/locates/modern';

const client$ = new ModernClient();
const clientAsBehaviorSubject = new BehaviorSubject<LocatesClient>(client$);

/**
 * For use in sagas only
 * Utilize useLocatesClient() in components
 */
export function getLocatesClient(): LocatesClient {
    return client$;
}

/**
 * React hook for access the Locates client
 * TODOLOCATES: this is used in 1 place for cancel all; just turn cancel all into a saga and delete this
 * @returns Locates client appropriate for the current user and environment
 */
export function useLocatesClient(): LocatesClient {
    return useObservableValue(clientAsBehaviorSubject, clientAsBehaviorSubject.value);
}
