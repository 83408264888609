import 'init/1-polyfills';
import 'init/2-containerWeb';
import 'init/3-prelude';
import { container } from '../ioc/StaticContainer';
import { createReactContainerAndRunStore } from '../lib/createReactContainerAndRunStore';
import { launchDarklyBrowserModule } from '../store/modules/launchDarklyBrowserModule';
import { localStoreModule } from '../store/modules/localStoreModule';

container.load(launchDarklyBrowserModule);
window.reactContainer = createReactContainerAndRunStore(localStoreModule);
