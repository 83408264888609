import type { FeatureFlagSet } from '../types';

export type SetFeatureFlags = { type: 'featureFlags::set'; flags: Partial<FeatureFlagSet> };

export const setFeatureFlags = (flags: Partial<FeatureFlagSet>): SetFeatureFlags => ({
    type: 'featureFlags::set',
    flags,
});

export type FeatureFlagAction = SetFeatureFlags;
