import type { Instant } from '@js-joda/core';
import type { IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { Strategy } from 'src/contracts/strategy';
import type { Universe } from 'src/contracts/universe';

export type UserRanChangesAction = {
    type: 'userRanChanges';
    tabId: string;
    strategy: Strategy<IndicatorImportViewModel>;
};
export const userRanChanges = (tabId: string, strategy: Strategy<IndicatorImportViewModel>): UserRanChangesAction => ({
    tabId,
    strategy,
    type: 'userRanChanges',
});

export type UpdateWidgetStrategy = {
    type: 'updateWidgetStrategy';
    tabId: string;
    strategy: Strategy<IndicatorImportViewModel>;
};
export const updateWidgetStrategy = (
    tabId: string,
    strategy: Strategy<IndicatorImportViewModel>,
): UpdateWidgetStrategy => ({
    strategy,
    tabId,
    type: 'updateWidgetStrategy',
});

/* New search alpha! */
export type SetDisplayingSearchAction = { type: 'setDisplayingSearch'; tabId: string; isShowing: boolean };
export type SetDisplayingResultsAction = { type: 'setDisplayingResults'; tabId: string; isShowing: boolean };
export type SetDisplayingIdeasAction = { type: 'setDisplayingIdeas'; tabId: string; isShowing: boolean };
export type SetSearchAlphaLastSubmitAction = {
    type: 'setSearchAlphaLastSubmit';
    tabId: string;
    lastSubmit: Instant | undefined;
};
export type SetSearchAlphaStrategyAction = {
    type: 'setSearchAlphaStrategy';
    tabId: string;
    strategy: Strategy;
};
export type SetSearchAlphaUniverseAction = {
    type: 'setSearchAlphaUniverse';
    tabId: string;
    universe: Universe | null;
};
export type StoreSplitPercentageAction = { type: 'storeSplitPercentage'; tabId: string; splitPercentage: number };

export const setDisplayingSearch = (tabId: string, isShowing: boolean): SetDisplayingSearchAction => ({
    tabId,
    isShowing,
    type: 'setDisplayingSearch',
});
export const setDisplayingResults = (tabId: string, isShowing: boolean): SetDisplayingResultsAction => ({
    tabId,
    isShowing,
    type: 'setDisplayingResults',
});
export const setDisplayingIdeas = (tabId: string, isShowing: boolean): SetDisplayingIdeasAction => ({
    tabId,
    isShowing,
    type: 'setDisplayingIdeas',
});
export const setSearchAlphaLastSubmit = (
    tabId: string,
    lastSubmit: Instant | undefined,
): SetSearchAlphaLastSubmitAction => ({
    tabId,
    lastSubmit,
    type: 'setSearchAlphaLastSubmit',
});
export const setSearchAlphaStrategy = (tabId: string, strategy: Strategy): SetSearchAlphaStrategyAction => ({
    tabId,
    strategy,
    type: 'setSearchAlphaStrategy',
});
export const setSearchAlphaUniverse = (tabId: string, universe: Universe | null): SetSearchAlphaUniverseAction => ({
    tabId,
    universe,
    type: 'setSearchAlphaUniverse',
});
export const storeSplitPercentage = (tabId: string, splitPercentage: number): StoreSplitPercentageAction => ({
    tabId,
    splitPercentage,
    type: 'storeSplitPercentage',
});

export type SearchAlphaActions =
    | UserRanChangesAction
    | UpdateWidgetStrategy
    | SetDisplayingSearchAction
    | SetDisplayingResultsAction
    | SetDisplayingIdeasAction
    | SetSearchAlphaLastSubmitAction
    | SetSearchAlphaStrategyAction
    | SetSearchAlphaUniverseAction
    | StoreSplitPercentageAction;
