export type UpdateLensSymbolAction = { type: 'updateAlphaLensSymbol'; tabId: string; symbol: string };
export const updateAlphaLensSymbol = (tabId: string, symbol: string): UpdateLensSymbolAction => ({
    tabId,
    symbol,
    type: 'updateAlphaLensSymbol',
});

export type UserSelectedNewAlphaLensSymbolAction = {
    type: 'userSelectedNewAlphaLensSymbol';
    symbol: string;
    tabId: string;
};

export const userSelectedNewAlphaLensSymbol = (
    tabId: string,
    symbol: string,
): UserSelectedNewAlphaLensSymbolAction => ({
    symbol,
    tabId,
    type: 'userSelectedNewAlphaLensSymbol',
});

export type AlphaLensActions = UpdateLensSymbolAction | UserSelectedNewAlphaLensSymbolAction;
