import { DateTimeFormatter, Instant, OffsetDateTime, ZoneId, nativeJs } from '@js-joda/core';
import numbro from 'numbro';
import { LocateOrderStatus } from 'src/contracts/locates/orders';

export const locatesPriceFormatter = (value: number): string => {
    const [, decimal] = parseFloat(value.toFixed(4)).toString().split('.');
    const trimMantissa = decimal?.length > 2;
    const mantissa = trimMantissa ? 4 : 2;
    return numbro(value).format({ thousandSeparated: true, mantissa, trimMantissa });
};

export const timestampDateTimeFormatter = DateTimeFormatter.ofPattern('h:mm:ss');
export const timestampFormatter = (value: string | number | Date | Instant): string => {
    if (typeof value === 'string') {
        return OffsetDateTime.parse(value, DateTimeFormatter.ISO_INSTANT).format(timestampDateTimeFormatter);
    } else if (typeof value === 'number') {
        return OffsetDateTime.ofInstant(Instant.ofEpochMilli(value), ZoneId.SYSTEM).format(timestampDateTimeFormatter);
    } else if (value instanceof Date) {
        return OffsetDateTime.from(nativeJs(value)).format(timestampDateTimeFormatter);
    } else {
        return OffsetDateTime.ofInstant(value, ZoneId.SYSTEM).format(timestampDateTimeFormatter);
    }
};

export const statusFormat = {
    [LocateOrderStatus.sent]: 'Sent',
    [LocateOrderStatus.pending]: 'Pending',
    [LocateOrderStatus.quoted]: 'Quoted',
    [LocateOrderStatus.pendingContinuous]: 'Pending Continuous',
    [LocateOrderStatus.failed]: 'Failed',
    [LocateOrderStatus.created]: 'Created',
    [LocateOrderStatus.cancelled]: 'Cancelled',
    [LocateOrderStatus.provided]: 'Offered',
    [LocateOrderStatus.accepted]: 'Accepted',
    [LocateOrderStatus.rejected]: 'Rejected',
} as const;

// Sorting on the status column sorts by two broad groups, as an
// additional, secondary sort within those groups will usually be used
export const statusSortPriority = {
    [LocateOrderStatus.sent]: 3,
    [LocateOrderStatus.provided]: 3,
    [LocateOrderStatus.quoted]: 3,
    [LocateOrderStatus.pending]: 3,
    [LocateOrderStatus.pendingContinuous]: 3,
    [LocateOrderStatus.created]: 3,
    [LocateOrderStatus.accepted]: 2,
    [LocateOrderStatus.failed]: 1,
    [LocateOrderStatus.cancelled]: 1,
    [LocateOrderStatus.rejected]: 1,
} as const;

export const defaultQuantityStep = 100;
