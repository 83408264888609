import type { QueryObserverOptions } from '@tanstack/react-query';
import { getUniverseById } from 'src/components/menus/Universe/api';
import type { ConcreteUniverse } from 'src/contracts/universe';

/**
 * Generates a query to get a universe by an id
 *
 * @param id string id of the universe, or null
 * @returns an object with queryKey, queryFn, and enabled
 */
export function getUniverseByIdQuery(id: string | null): QueryObserverOptions<ConcreteUniverse | undefined> {
    return {
        queryKey: ['universe', id],
        queryFn: () => getUniverseById(id!),
        enabled: !!id,
    };
}
