import type { CompleteBootstrapAction } from '../../actions/app';
import type { SetTokenAction } from '../../actions/auth';
import type { LocatesSocketSetupCompletedEvent } from '../../actions/locates/locatesSocket';
import { makeLocatesEmissionAction } from '../../actions/locates/locatesSocket';
import { locatesSocketSaga } from '../../channels/locates';
import { getLocatesAuthPayloadFromState } from '../../selectors/locates/locatesSocket';
import { fork, put, select, takeLatest } from 'redux-saga/effects';
import { setIsLocatesBoostrapComplete } from 'src/store/actions/locates/locates';

function* registerActivity(_: LocatesSocketSetupCompletedEvent | SetTokenAction) {
    try {
        const token: string | undefined = yield select(getLocatesAuthPayloadFromState);
        yield put(setIsLocatesBoostrapComplete(false));
        yield put(
            makeLocatesEmissionAction({
                type: 'auth',
                payload: [token],
            }),
        );
    } finally {
    }
}
function* activityTrackingSagas(action: LocatesSocketSetupCompletedEvent) {
    try {
        // initial auth
        yield registerActivity(action);
        // listen for future changes to authentication state
        yield takeLatest(['access-token::set', 'masq::access-token::set'], registerActivity);
    } finally {
    }
}

export function* activityTrackingStartupSagas(_: CompleteBootstrapAction) {
    try {
        // Connection management loop
        yield fork(locatesSocketSaga);
        yield takeLatest('locates::socket::setup::completed', activityTrackingSagas);
    } finally {
        // We crashed the management loop and will not reconnect
    }
}

// for the entire locates process, this saga will run first to set up the socket
// and it will then set up the socket and auth with it
// and after receiving a confirmtion that auth is successful it will then
// pull all of the locates data from the server
export function* locatesSocketSagas() {
    yield takeLatest('locates::beginLocatesSetupProcess', activityTrackingStartupSagas);
}
