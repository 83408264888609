export type UpdateOrderEntrySymbolAction = { type: 'updateOrderEntrySymbol'; tabId: string; symbol: string };
export const updateOrderEntrySymbol = (tabId: string, symbol: string): UpdateOrderEntrySymbolAction => ({
    tabId,
    symbol,
    type: 'updateOrderEntrySymbol',
});

export type UserSelectedNewOrderEntrySymbolAction = {
    type: 'userSelectedNewOrderEntrySymbol';
    symbol: string;
    tabId: string;
};

export const userSelectedNewOrderEntrySymbol = (
    tabId: string,
    symbol: string,
): UserSelectedNewOrderEntrySymbolAction => ({
    symbol,
    tabId,
    type: 'userSelectedNewOrderEntrySymbol',
});

export type UpdateOrderEntryAccountAction = {
    type: 'updateOrderEntryAccount';
    tabId: string;
    accountId: string | null;
};
export const updateOrderEntryAccount = (tabId: string, accountId: string | null): UpdateOrderEntryAccountAction => ({
    tabId,
    accountId,
    type: 'updateOrderEntryAccount',
});

export type OrderEntryActions =
    | UpdateOrderEntrySymbolAction
    | UserSelectedNewOrderEntrySymbolAction
    | UpdateOrderEntryAccountAction;
