import type { RootState } from '..';
import type {
    AddToSymbolCacheAction,
    RemoveFromSymbolCacheAction,
    SetDefaultLineSettingsAction,
    SetSymbolCacheAction,
} from '../actions/ui';
import { select } from '../effects';
import { ChronoUnit, Instant } from '@js-joda/core';
import { all, takeLatest } from 'redux-saga/effects';
import { SYMBOL_CACHE_KEY } from 'src/constants/storage-keys';
import { DEFAULT_LINE_SETTINGS_CACHE_KEY } from 'src/if-then/model';
import { container } from 'src/ioc/StaticContainer';
import { localforage } from 'src/lib/serialization/localForage';

const log = container.get('Logger').getSubLogger({ name: 'ui-sagas' });

// This exists just to sync up setting the defaultLineSettings field to defaultLineSettings
export function* onDefaultLineSettingsSet(action: SetDefaultLineSettingsAction) {
    try {
        // Now ideally we'd use `yield call` for testability but localforage is having issues
        // because it's immediately relying on some global `this`
        yield localforage.setItem(DEFAULT_LINE_SETTINGS_CACHE_KEY, action.defaultLineSettings);
    } catch (error) {
        if (error instanceof Error) {
            log.error({ message: error.message, error });
        } else {
            log.fatal({ message: 'Unknown error', details: error });
        }
    }
}

export function* onSymbolCacheSet(
    _action: SetSymbolCacheAction | AddToSymbolCacheAction | RemoveFromSymbolCacheAction,
) {
    const symbolCache: RootState['ui']['symbolCache'] = yield select((x) => x.ui.symbolCache);
    const expiresAt = Instant.now().minus(7, ChronoUnit.DAYS);
    try {
        yield localforage.setItem(
            SYMBOL_CACHE_KEY,
            symbolCache.filter((x) => x.lastUse.isAfter(expiresAt)),
        );
    } catch (error) {
        if (error instanceof Error) {
            log.error({ message: error.message, error });
        } else {
            log.fatal({ message: 'Unknown exception', details: error });
        }
    }
}

export function* uiSagas() {
    yield all([
        takeLatest('default-line-settings::set', onDefaultLineSettingsSet),
        takeLatest('symbol-cache::set', onSymbolCacheSet),
        takeLatest('symbol-cache::add', onSymbolCacheSet),
        takeLatest('symbol-cache::remove', onSymbolCacheSet),
    ]);
}
