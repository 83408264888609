export type UpdateOrdersSymbolFilterAction = { type: 'updateOrdersSymbolFilter'; tabId: string; symbol: string };
export const updateOrdersSymbolFilter = (tabId: string, symbol: string): UpdateOrdersSymbolFilterAction => ({
    tabId,
    symbol,
    type: 'updateOrdersSymbolFilter',
});

export type UpdateOrdersAccountAction = {
    type: 'updateOrdersAccount';
    tabId: string;
    accountId: string | null;
};
export const updateOrdersAccount = (tabId: string, accountId: string): UpdateOrdersAccountAction => ({
    tabId,
    accountId,
    type: 'updateOrdersAccount',
});

export type UserDoubleClickedOrderFromTableAction = {
    type: 'userDoubleClickedOrderFromTable';
    symbol?: string;
    accountId?: string;
    tabId: string;
};

export const userDoubleClickedOrder = (
    tabId: string,
    symbol?: string,
    accountId?: string,
): UserDoubleClickedOrderFromTableAction => ({
    symbol,
    accountId,
    tabId,
    type: 'userDoubleClickedOrderFromTable',
});

export type OrdersActions =
    | UpdateOrdersSymbolFilterAction
    | UpdateOrdersAccountAction
    | UserDoubleClickedOrderFromTableAction;
