import type { ConcreteUniverse } from 'src/contracts/universe';

export type InitializeUniversesAction = {
    type: 'initializeUniverses';
    universes: ConcreteUniverse[];
};
export const initializeUniverses = (universes: ConcreteUniverse[]): InitializeUniversesAction => {
    return {
        type: 'initializeUniverses',
        universes,
    };
};

export type RefetchStoredUniverseAction = {
    type: 'refetchStoredUniverse';
    universeId: string;
};
export const refetchStoredUniverse = (universeId: string): RefetchStoredUniverseAction => {
    return {
        type: 'refetchStoredUniverse',
        universeId,
    };
};

export type SetUniverseAction = {
    type: 'setUniverse';
    universe: ConcreteUniverse;
};
export const setUniverse = (universe: ConcreteUniverse): SetUniverseAction => ({
    universe,
    type: 'setUniverse',
});

export type UserPublishedTickerAction = {
    type: 'userPublishedTicker';
};
export const userPublishedTicker = (): UserPublishedTickerAction => ({
    type: 'userPublishedTicker',
});

export type AddTagToUniverseAction = {
    tag: string;
    universeId: string;
    type: 'addTagToUniverse';
};
export const addTagToUniverse = (payload: Omit<AddTagToUniverseAction, 'type'>): AddTagToUniverseAction => ({
    type: 'addTagToUniverse',
    ...payload,
});

export type RemoveTagFromUniverseAction = {
    tag: string;
    universeId: string;
    type: 'removeTagFromUniverse';
};
export const removeTagFromUniverse = (
    payload: Omit<RemoveTagFromUniverseAction, 'type'>,
): RemoveTagFromUniverseAction => ({
    type: 'removeTagFromUniverse',
    ...payload,
});

export type UniverseAction =
    | SetUniverseAction
    | InitializeUniversesAction
    | RefetchStoredUniverseAction
    | UserPublishedTickerAction
    | AddTagToUniverseAction
    | RemoveTagFromUniverseAction;
