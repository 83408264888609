import { api } from '../api';
import type { TemplateColumnBase } from 'src/contracts/column-template';
import type { DictionaryBackedData } from 'src/contracts/dictionary';
import type { OrderEntryTicker, ToolbarItem, NewToolbarItem } from 'src/contracts/user-preference';
import type { AlphaLensContent } from 'src/contracts/workspace';
import { v4 } from 'uuid';

export function getAlphaLensDefaultWidget(): Promise<AlphaLensContent> {
    return api.get(`/user-preferences/alpha-lens-default`).then((res) => res.data);
}

export function getToolbarItems(): Promise<ToolbarItem[]> {
    return api
        .get(`/user-preferences/toolbar/items`)
        .then((res) => res.data)
        .then((items) => items.map((item: ToolbarItem) => ({ ...item, id: v4() })));
}

export function updateToolbarItemsList({ payload }: { payload: NewToolbarItem[] }) {
    return api.post(`/user-preferences/toolbar/items`, payload);
}

export function getDefaultTopbarFormulas(): Promise<DictionaryBackedData[]> {
    return api.get('/user-preferences/topbar/default-formulas').then((res) => res.data);
}

// workspace
export function setDefaultWorkspaceId(workspaceId: string): Promise<string> {
    return api.post(`/user-preferences/default-workspace`, { workspaceId }).then((res) => res.data);
}

// Order Entry
export function getOrderEntryTickers(): Promise<OrderEntryTicker[]> {
    return api.get(`/user-preferences/order-entry/tickers`).then((res) => res.data);
}

export async function getPinnedUniverses(): Promise<string[]> {
    return (await api.get(`/user-preferences/pinned-universes`)).data;
}

export async function getSuggestedColumns(key: string): Promise<TemplateColumnBase[]> {
    return (await api.get(`/user-preferences/suggested-columns`, { params: { key } })).data;
}
