import { setStoreForApi } from 'src/api';
import type { TypedContainerModule } from 'src/features/ioc/TypedContainer';
import { isNative } from 'src/features/native';
import { initReactContainer } from 'src/ioc/ReactContainer';
import type { StaticBindings } from 'src/ioc/types';
import { beginBootstrap } from 'src/store/actions/app';

export const createReactContainerAndRunStore = (reduxContainerModule: TypedContainerModule<StaticBindings>) => {
    // Create the container
    const container = initReactContainer();
    container.load(reduxContainerModule);

    const store = container.get('Store');
    window.store = store;

    // Some store-related services and values are instantiated here after the store is created
    setStoreForApi(store);

    // Instantiate select singletons after store creation
    container.get('History');
    container.get('SyncInternalConfigurationsService');
    container.get('SyncToExternalsService');

    if (!isNative) {
        store.run();
        const state = store.getState();
        if (!state.app.bootstrapped) {
            store.dispatch(beginBootstrap());
        }
    }

    return container;
};
