import { bootstrapLocates, onStatusChange, setLocatesStatus } from '../actions/locates/locates';
import type { LocatesEmissionAction, LocatesSocketConnectedEvent } from '../actions/locates/locatesSocket';
import {
    locatesSocketConnectedAction,
    locatesSocketDisconnectedAction,
    locatesSocketSetupCompletedAction,
    onOrderUpdateActionCreator,
} from '../actions/locates/locatesSocket';
import { getLocatesSocketConnectionSettingsFromState } from '../selectors/locates/locatesSocket';
import { createSocketConnectChannel, handleEmissions, handleSocket } from './socket';
import { createLocatesSocket } from './util';
import type { EventChannel } from 'redux-saga';
import { all, call, put, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects';
import type { Socket } from 'socket.io-client';
import { container } from 'src/ioc/StaticContainer';

const log = container.get('Logger').getSubLogger({ name: 'locates-sagas' });

/**
 * Saga setting up the locates socket
 * and make sure its connected
 * @param socket socket to prepare for use
 */
function* setupLocatesSocket(socket: Socket) {
    try {
        const locatesConnectChannel: EventChannel<Record<string, never>> = yield createSocketConnectChannel(socket);

        if (!socket.active) {
            // not connected
            yield call({
                context: socket,
                fn: socket.connect,
            });
        }

        while (true) {
            // wait for connection and dispatch notification
            // take(END) will cause the saga to terminate by jumping to the finally block
            // this works because locatesConnectChannel will only "emit" i.e. trigger take
            // when socket.on('connect'); and then it passes down to the puts below and
            // restarts the loop to wait for the next socket.on('connect')
            yield take(locatesConnectChannel);

            // tell redux that we're connected
            yield put(locatesSocketConnectedAction());

            // set status to connected
            yield put(setLocatesStatus('connected'));
        }
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Setting up locates socket failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    } finally {
        // Disconnected; prepare for recycle
        yield call({
            context: socket,
            fn: socket.disconnect, // dispose this socket
        });
        yield put(locatesSocketDisconnectedAction());
    }
}

/**
 * Create a saga which will handle locates socket events
 * @param socket socket to create channels on
 * @returns saga handling events incoming from locates socket
 */
function setupLocatesChannels(socket: Socket) {
    return function* locatesWorkflowHandler(_: LocatesSocketConnectedEvent) {
        yield all([
            // put all listeners here
            // handleSocket "runs infinitely" so it needs to be in an 'all' with
            // the action that tells us the socket is ready to go

            // TODOLOCATES PLAT-5358 - keep track of auth status in redux and make the locates status indicator reflect that
            // when we're authorized to the socket, tell redux to start bootstrap process
            handleSocket(socket, 'auth.success', [bootstrapLocates]),
            handleSocket(socket, 'auth.error', []),

            // set status to connecting
            handleSocket(socket, 'reconnect_attempt', [() => onStatusChange('connecting')]),

            // set status to disconnected
            handleSocket(socket, 'disconnect', [locatesSocketDisconnectedAction, () => onStatusChange('disconnected')]),
            handleSocket(socket, 'reconnect_error', [
                locatesSocketDisconnectedAction,
                () => onStatusChange('disconnected'),
            ]),

            // handle order updates
            handleSocket(socket, 'order.update', [onOrderUpdateActionCreator]),

            put(locatesSocketSetupCompletedAction()),
        ]);
    };
}

/**
 * Create a saga mapping locates socket emission actions to generic emit action payloads
 * @param socket socket to use for emissions
 * @returns saga handling locates socket emissions actions
 */
function handleLocatesEmissions(socket: Socket) {
    const handlerSaga = handleEmissions(socket);
    return function* locatesEmissionHandler(action: LocatesEmissionAction) {
        yield handlerSaga(action.payload);
    };
}

export interface LocatesSocketConnectionSettings {
    uri: string;
}

/**
 * Saga creating a WebSocket connection to locates and setting it up for use by the application
 */
export function* locatesSocketSaga() {
    try {
        const authPayload: LocatesSocketConnectionSettings | undefined = yield select(
            getLocatesSocketConnectionSettingsFromState,
        );
        if (!authPayload) return;

        const socket: Socket = yield call(createLocatesSocket, { uri: authPayload.uri });
        yield all([
            spawn(setupLocatesSocket, socket),
            takeLatest('locates::socket::connected', setupLocatesChannels(socket)),
            takeEvery('locates::socket::emit', handleLocatesEmissions(socket)),
        ]);
    } finally {
    }
}
