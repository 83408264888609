import { getDefaultUniverse } from '../api';
import type { QueryObserverOptions } from '@tanstack/react-query';
import type { ConcreteUniverse } from 'src/contracts/universe';

/**
 * Generates a query to get the default universe
 *
 * @param id string id of the universe, or null
 * @returns an object with queryKey and queryFn
 */
export function getDefaultUniverseQuery(): QueryObserverOptions<ConcreteUniverse | undefined> {
    return {
        queryKey: ['default-universe'],
        queryFn: () => getDefaultUniverse(),
    };
}
